import { GetListQuery, useGetListQuery } from '../reactQueryHooks';
import useAuth from '@hooks/useAuth';
import useListNameFormatter from '@hooks/useListNameFormatter';
import { UseQueryOptions } from '@tanstack/react-query';

import useGetCommonVariables from 'apis/useGetCommonVariables';

const withNameFormatter = (
    formatter: (name?: string) => string,
    data: GetListQuery
): GetListQuery => ({
    ...data,
    list: {
        ...data.list,
        name: formatter(data.list.name),
    },
});

const useGetList = (
    listId: string,
    options?: UseQueryOptions<GetListQuery>
) => {
    const { accessToken } = useAuth();
    const formatListName = useListNameFormatter();
    const commonVars = useGetCommonVariables();
    return useGetListQuery(
        { ...commonVars, listId },
        {
            ...options,
            select: data =>
                withNameFormatter(
                    formatListName,
                    options?.select?.(data) || data
                ),
            enabled: Boolean(accessToken) && listId !== '',
            keepPreviousData: true,
        }
    );
};

export default useGetList;
